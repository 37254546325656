<template>
  <div class="card-header justify-content-between head-sort">
    <custom-select-2
        v-model="searchParams.searchStatus"
        name="status"
        label="Статус:"
        :options="['Не выбрано', 'Успех', 'Ошибка']"
    />
    <button @click="clearAllSearch" class="btn btn-w reset-btn">
      Очистить
    </button>
  </div>
  <div class="card-body">
    <div v-if="excelStaff">
      <base-table
          v-if="shownData.length"
          :columns="staffFields"
          :rows="shownData"
      />

      <table-pagination
          v-if="shownData.length"
          :key="updatePagination"
          :totalPages="1"
          :countOnPage="countOnPage"
          :total="totalResults ?? rowData.length"
          :count="countOnPage > shownData.length ? shownData.length : countOnPage"
          @changePage="changePage"
      />
      <div v-else>
        <p class="no_results">Нет результатов</p>
      </div>
    </div>
    <div v-else class="table-preloader">
      <preloader/>
    </div>
  </div>
</template>

<script>
import {
  computed,
  onBeforeUnmount,
  reactive,
  ref,
  watch,
} from "@vue/runtime-core";
import VPagination from "@hennge/vue3-pagination";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import Preloader from "@/components/Technical/Preloader.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import BaseTable from "@/components/Tables/BaseTable.vue";

export default {
  name: "group-passings-table",
  components: {
    BaseTable, TablePagination,
    CustomSelect2,
    VPagination,
    Preloader,
  },
  setup() {
    const store = useStore(),
        route = useRoute();

    const countOnPage = ref(15),
        page = ref(1),
        searchParams = reactive({
          searchStatus: "Не выбрано",
        }),
        isPreloader = ref(false);
    const excelStaff = computed(() => store.state.group.excelStaff),
        rowData = computed(() => {
          return excelStaff.value.map((staff) => {
            return {
              name: staff.name,
              email: staff.email,
              status: staff.status ? "Успех" : "Ошибка",
              error: staff.error ? staff.error : null,
            };
          });
        });

    const staffFields = [
      {
        field: "name",
        headerName: "Имя",
      },
      {
        field: "email",
        headerName: "Email",
      },
      {
        field: "excel_status",
        headerName: "Статус",
      },
      {
        field: "error",
        headerName: "Ошибка",
      },
    ];

    const shownData = computed(() => {

      let data = rowData.value ?? [];

      if (data.length) {

        if (searchParams.searchStatus !== 'Не выбрано') {
          data = data.filter((el => el.status === searchParams.searchStatus));
        }

        totalResults.value = data.length;
        data = data.slice(pageDataStart.value, pageDataStart.value + countOnPage.value);
      }

      return data;
    });

    const pageDataStart = ref(0);
    const updatePagination = ref(false);
    const totalResults = ref();

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    const clearAllSearch = () => {
      searchParams.searchStatus = "Не выбрано";
    };

    watch([searchParams], () => {
      totalResults.value = 0;
      pageDataStart.value = 0;
      updatePagination.value = !updatePagination.value;
    });

    onBeforeUnmount(() => {
      store.commit("group/setExcelStaff", []);
    });

    return {
      route,
      countOnPage,
      isPreloader,
      searchParams,
      page,
      clearAllSearch,
      changePage,
      excelStaff,
      staffFields,
      updatePagination,
      totalResults,
      shownData,

      rowData,
    };
  },
};
</script>

<style lang="scss" scoped>
.head-sort {
  .form-group {
    min-width: 50%;
  }
}

.card-header {
  row-gap: 20px;
}

.no_results {
  padding: 40px 10px;
}

@media (max-width: 768px) {
  .form-group {
    width: 100%;
  }
}
</style>
